body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  overscroll-behavior-y: contain;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.back-to-site {
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 0 12px;
  height: 40px;
  background-color: rgba(0,0,0,0.3);
  font-weight: bold;
  color: white;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.back-to-site:hover {
  background-color: rgba(0,0,0,0.35);
}

.btn-secondary, .btn-secondary:active, .btn-secondary:visited {
  background-color: rgba(0,0,0,0.3) !important;
  border: 0 !important;
}


.btn-secondary:hover {
  background-color: rgba(0,0,0,0.35) !important;
  border: 0 !important;
}

.btn-group .btn + .btn {
  margin-left : 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}



.btn p.label {
  font-size: 0.75em;
  margin-bottom: 0%;
}


.side-menu {
  position: relative;
  width: 18%;
  max-width: 210px;
}

.side-menu .list {
  position: relative;
  max-height: 700px;
  overflow-y: scroll;
}

/* .side-menu .list-group {
  position: relative;
  max-height: 300px;
  overflow-y: scroll;
} */

.side-menu .rc-slider {
  /* padding: 10px 0; */
  padding: 10px 0;
}


.my-chrome-picker {
  width: 100% !important;
  box-shadow: none !important;
  /* margin-bottom: 20px !important; */
}

.circle-picker {
  margin-top: 14px;
}

.language-selector {
  position: absolute !important;
  margin-top: 0px  !important;
  margin-right: 0px  !important; 
  float: right !important;
  /* display: flex; */
}
